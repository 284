import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { shallow } from '@jwp/ott-common/src/utils/compare';
import type { Playlist, PlaylistItem } from '@jwp/ott-common/types/playlist';
import { useAccountStore } from '@jwp/ott-common/src/stores/AccountStore';
import { useConfigStore } from '@jwp/ott-common/src/stores/ConfigStore';
import { filterPlaylist, getFiltersFromConfig } from '@jwp/ott-common/src/utils/collection';
import { mediaURL } from '@jwp/ott-common/src/utils/urlFormatting';

import type { ScreenComponent } from '../../../../../types/screens';
import CardGrid from '../../../../components/CardGrid/CardGrid';
import Filter from '../../../../components/Filter/Filter';

import styles from './PlaylistGrid.module.scss';
import { useLocation, useNavigate } from 'react-router';
import { PLAYLIST_LIMIT } from '../../../../../../../packages/common/src/constants';
import ApiService from '@jwp/ott-common/src/services/ApiService';
import { getModule } from '@jwp/ott-common/src/modules/container';
import Button from '../../../../../../../packages/ui-react/src/components/Button/Button';
import useBreakpoint, { Breakpoint } from '@jwp/ott-ui-react/src/hooks/useBreakpoint';
import { useTranslation } from 'react-i18next';
import { modalURLFromLocation } from '../../../../../../../packages/ui-react/src/utils/location';
import axios from 'axios';

const PlaylistGrid: ScreenComponent<Playlist> = ({ data, isLoading }) => {
  const { config, accessModel } = useConfigStore(({ config, accessModel }) => ({ config, accessModel }), shallow);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const breakpoint = useBreakpoint();
  const apiService = getModule(ApiService);
  const location = useLocation();

  const [filter, setFilter] = useState<string>('');
  const [count, setCount] = useState<number>(PLAYLIST_LIMIT);
  const [updatedPlaylist, setUpdatedPlaylist] = useState<any>([]);
  const [isLive, setIsLive] = useState<boolean>(false);
  const categories = getFiltersFromConfig(config, data.feedid);
  const filteredPlaylist = useMemo(() => filterPlaylist(data, filter), [data, filter]);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const shouldShowFilter = Boolean(categories.length);

  // User
  const { user, subscription } = useAccountStore(({ user, subscription }) => ({ user, subscription }), shallow);

  useEffect(() => {
    // reset filter when the playlist id changes
    setFilter('');
  }, [data.feedid]);

  const pageTitle = `${data.title} - ${config.siteName}`;

  const getUrl = (playlistItem: PlaylistItem) => mediaURL({ media: playlistItem, playlistId: playlistItem.feedid });
  const getDRMUrl = (playlistItem: PlaylistItem) => mediaURL({ media: playlistItem, playlistId: playlistItem.feedid, play: true });

  const handleLoadMoreClick = async () => {
    //Increase count for each click
    setCount(count + PLAYLIST_LIMIT);

    //playlist length to find the end of pagination
    const playListLength = (updatedPlaylist?.playlist?.length ? updatedPlaylist?.playlist?.length : filteredPlaylist?.playlist?.length) ?? 0;

    if (playListLength % PLAYLIST_LIMIT !== 0) {
      setShowLoadMore(false);
      return;
    }

    let combinedPlayList;
    const fetchedPlaylist = await apiService.getPlaylistById(filteredPlaylist?.feedid, {
      page_limit: PLAYLIST_LIMIT.toString(),
      page_offset: (count + 1).toString(),
    });

    if (fetchedPlaylist) {
      if (updatedPlaylist?.playlist?.length) {
        combinedPlayList = updatedPlaylist?.playlist.concat(fetchedPlaylist?.playlist);
      } else {
        combinedPlayList = filteredPlaylist?.playlist.concat(fetchedPlaylist?.playlist);
      }

      if (combinedPlayList) {
        fetchedPlaylist.playlist = combinedPlayList;
        setUpdatedPlaylist(fetchedPlaylist);
      }
    }
  };

  const handleGetDrmContent = async (playlistItem: any) => {
    if (filteredPlaylist?.isLiveChannel) {
      const { data: drmData } = await axios({
        method: 'get',
        url: `https://cdn.jwplayer.com/v2/media/${playlistItem?.mediaid}`,
      });
      setIsLive(true);
      playlistItem.title = drmData.title;
      navigate(getDRMUrl(playlistItem));
    } else {
      setIsLive(false);
    }
  };

  useEffect(() => {
    if (filteredPlaylist?.playlist?.length < PLAYLIST_LIMIT) {
      setShowLoadMore(false);
    }

    filteredPlaylist?.playlist.map((playlistItem: PlaylistItem) => {
      handleGetDrmContent(playlistItem);
    });
  }, [filteredPlaylist, user, isLive, handleGetDrmContent]);

  return (
    <div className={styles.playlist}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} />
        <meta name="twitter:title" content={pageTitle} />
      </Helmet>
      <div className={`${styles.main} ${isLive && styles.hideMain}`}>
        <header className={styles.header}>
          <h1>{data.title}</h1>
          {shouldShowFilter && <Filter name="genre" value={filter} defaultLabel="All" options={categories} setValue={setFilter} />}
        </header>
        <CardGrid
          getUrl={getUrl}
          playlist={updatedPlaylist?.playlist ? updatedPlaylist : filteredPlaylist}
          accessModel={accessModel}
          isLoggedIn={!!user}
          hasSubscription={!!subscription}
          isLoading={isLoading}
          headingLevel={2}
        />
        {showLoadMore && (
          <div className={styles.loadMoreBtn}>
            <Button
              label={t('Load More')}
              aria-label={t('loadMore')}
              onClick={handleLoadMoreClick}
              color={'default'}
              fullWidth={breakpoint < Breakpoint.md}
              size="large"
              type="submit"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PlaylistGrid;
