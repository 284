import InPlayer from '@inplayer-org/inplayer.js';
import { injectable } from 'inversify';

@injectable()
export default class JWPEntitlementService {
  getJWPMediaToken = async (configId: any, mediaId: string, user?: any) => {
    try {
      let tokenData: any;

      if (user) {
        const { data } = await InPlayer.Asset.getSignedMediaToken(configId, mediaId);
        tokenData = data;
      } else {
        tokenData = await fetch(`https://services.inplayer.com/v2/items/jw-media/token?app_config_id=${configId}&media_id=${mediaId}`).then((res) => {
          return res.json();
        });
      }

      return tokenData?.token;
    } catch {
      throw new Error('Unauthorized');
    }
  };
}
